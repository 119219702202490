import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import "twin.macro";

import Process from "../../static/images/triangular-process.png";
import { Container } from "../components/Container";
import { Layout } from "../components/Layout/Layout";
import ListWithBullet from "../components/ListWithBullet";
import TheBigTrioLinks from "../components/TheBigTrioLinks";
import { getTranslationList } from "../utils/common";

const Benefits: React.FC = () => {
  const { t } = useTranslation();

  const list = getTranslationList(t, "benefits.list");

  return (
    <Layout
      title={"Benefits"}
      ogTitle={"Triangular-pod"}
      author={"Triangular-pod"}
      ogImage={Process}
      ogDescription={
        "We provide access to available production capacities and experts to people that need to use these resources to create new products. We aim for excellent communication with our partners to understand their requirements and find the perfect match between materials, processing systems, and knowledge."
      }
      ogURL={"www.triangular-pod.com/benefits/"}
    >
      <Container tw="pt-16 pb-32">
        <article tw="mx-auto text-center max-w-prose">
          <h1 tw="font-black text-4xl">{t("benefits.header")}</h1>
          <p tw="mt-8 mb-16 mx-auto text-2xl">{t("benefits.subheader")}</p>
          <p tw="mb-8 mx-auto text-xl">{t("benefits.slogan")}</p>
        </article>

        <TheBigTrioLinks />

        <div tw="flex">
          <ListWithBullet list={list} tw="ml-auto" />
        </div>
      </Container>
    </Layout>
  );
};

export default Benefits;
